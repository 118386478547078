import React, { useCallback, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { OTSession, OTPublisher, OTSubscriber } from 'opentok-react';
import PropTypes from 'prop-types';

import { OPENTOK_API_KEY } from 'src/util';
import logger from 'src/util/logger';

/**
 * OTSession tracks streams and makes them available with a context using the old context
 * API which makes it hard to get from inside our template. Extract it from the context and
 * make it available as a render prop.
 */
const WithStreams = ({ children }, { streams }) => children(streams);
WithStreams.contextTypes = {
  streams: PropTypes.arrayOf(PropTypes.object),
};

const VideoConferenceViewer = ({
  sessionId,
  token,
  onError,
  localAudioEnabled,
  localVideoEnabled,
  setSwitchCamera,
}) => {
  const classes = useStyles();
  const publisherRef = useRef(null);

  const switchCamera = useCallback(() => {
    if (publisherRef.current) {
      const publisher = publisherRef.current.getPublisher();
      if (publisher && publisher.cycleVideo) {
        try {
          publisher.cycleVideo().catch(err => {
            logger.error(err);
            if (err.stack) {
              logger.error(err.stack);
            }
          });
        } catch (err) {
          logger.error(err);
          if (err.stack) {
            logger.error(err.stack);
          }
        }
      }
    }
  }, [publisherRef.current]);
  useEffect(() => {
    if (setSwitchCamera) {
      setSwitchCamera(switchCamera);
    }
  }, [setSwitchCamera, switchCamera]);

  return (
    <OTSession apiKey={OPENTOK_API_KEY} sessionId={sessionId} token={token} onError={onError}>
      <WithStreams>
        {streams => {
          const subRows = streams.length < 4 ? 1 : Math.ceil(Math.sqrt(streams.length));
          const subColumns = Math.ceil(streams.length / subRows);

          return (
            <div
              className={classes.grid}
              style={{
                gridTemplateColumns: `repeat(${subColumns}, 1fr)`,
                gridTemplateRows: `${(100 / subRows).toString()}%`,
              }}
            >
              <div
                className={classNames([
                  classes.videoContainer,
                  streams && streams.length > 0 && classes.videoContainerSmall,
                ])}
              >
                <OTPublisher
                  ref={publisherRef}
                  properties={{
                    publishAudio: localAudioEnabled,
                    publishVideo: localVideoEnabled,
                    showControls: false,
                    width: '100%',
                    height: '100%',
                    ...(process.env.VIDEO_NAME_PROPERTY_UPDATE
                      ? { name: JSON.stringify({ userId: 'visitor', name: '' }) }
                      : {}),
                  }}
                  onError={onError}
                />
              </div>

              {streams.map(stream => (
                <div className={classes.videoContainer} key={`stream-id-${stream.id}`}>
                  <OTSubscriber
                    key={stream.id}
                    stream={stream}
                    properties={{
                      subscribeToAudio: true,
                      subscribeToVideo: true,
                      showControls: false,
                      width: '100%',
                      height: '100%',
                    }}
                    onError={onError}
                  />
                </div>
              ))}
            </div>
          );
        }}
      </WithStreams>
    </OTSession>
  );
};

const useStyles = makeStyles({
  grid: {
    width: '100%',
    height: '100%',
    display: 'grid',
    position: 'absolute', // take up 100% of the screen height, not of the anonymous OTSession div
  },
  videoContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',

    // The video container always wraps an anonymous div created by OTPublisher or OTSubscriber -
    // make sure that div fills the full size.
    '& > div': {
      width: '100%',
      height: '100%',
    },

    // Smaller preview at some right -- the absolute positioning removes it from the grid.
    '&$videoContainerSmall': {
      position: 'absolute',
      top: 20,
      right: 20,
      width: 160,
      height: 96,
      border: '1px solid #fcba00',
      zIndex: 2,
    },
  },
  videoContainerSmall: {}, // required for the reference above
});

export default VideoConferenceViewer;
